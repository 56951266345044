import React, { useRef, useState } from 'react';
import { AnimateProps } from '../utils/types';
import { Text3D, useGLTF } from '@react-three/drei';
import { MeshBasicMaterial, Vector3 } from 'three';
import { Select } from '@react-three/postprocessing';
import { useNavigate } from 'react-router-dom';

export default function Tent(props: AnimateProps){
    const textMaterial = useRef<MeshBasicMaterial>(new MeshBasicMaterial({color: "#ff6000"}));
    const [hover, setHover] = useState(false);
    const navigate = useNavigate();

    const lookAt = new Vector3(-8.3,1.5,13.5);
    const pos = new Vector3(-6,1.5,15);

    const obj = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/tent.gltf`);
    obj.scene.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
    })

    function OnAnimationFinished(){
        navigate("/about");
    }

    return <Select enabled={hover} onPointerDown={() => props.focus("tent", pos, lookAt, OnAnimationFinished)}>
        <group dispose={null} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)}>
            <Text3D position={[-14, 2.5, 12]} rotation-y={-0.35} rotation-z={-0.37} rotation-x={-0.8} scale-z={0.4} 
                font={`${process.env.PUBLIC_URL}/Agbalumo_Regular.json`} material={textMaterial.current} >
                About Me
            </Text3D>
            <primitive object={obj.scene} />
        </group>
    </Select>
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/tent.gltf`);