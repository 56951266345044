import React, { useRef, useState } from 'react';
import { AnimateProps, DreiGLTF } from '../utils/types';
import { useGLTF } from '@react-three/drei';
import { AnimationMixer, Vector3 } from 'three';
import { useFrame } from '@react-three/fiber';
import { Select } from '@react-three/postprocessing';

export default function Campfire(props: AnimateProps)
{
    const { animations, scene } = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/campfire.gltf`) as DreiGLTF;
    const [hover, setHover] = useState(false);
    const mixer = useRef(new AnimationMixer(scene));

    const lookAt = new Vector3(1.3,1.5,14);
    const pos = new Vector3(2.5,2,19);

    if (animations.length){
        animations.forEach(clip => {
            const action = mixer.current.clipAction(clip);
            action.play();
        })
    }

    scene.traverse(child => {
        child.castShadow = true;
        child.receiveShadow = true;
    })

    useFrame((_, delta) => {
        mixer.current.update(delta);
    });

    return <Select enabled={hover} onPointerDown={() => {console.log("Campfire Clicked"); props.focus("campfire", pos, lookAt)}}>
        <primitive object={scene} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)} />
    </Select>   
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/campfire.gltf`);