import React, { useEffect, useMemo, useRef } from "react";
import { Clone, useAnimations, useGLTF } from "@react-three/drei";
import { Vector3 } from "three";
import { DreiGLTF } from "../utils/types";

interface ArrowProps{
    position: Vector3;
    scale: number;
}

export default function Arrow(props: ArrowProps){
    const group = useRef(null);
    const { animations, scene, materials } = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/arrow.gltf`) as DreiGLTF;
    useMemo(() => {
        materials.ArrowMat?.color.set("#ff0000");
    }, [materials]);
    const clone = useRef(scene.clone());
    const { actions } = useAnimations(animations, group);
    clone.current.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
    });

    useEffect(() => {
        actions.Hover?.play();
    }, []);

    return <group ref={group} dispose={null} position={props.position} scale={props.scale}>
        <Clone ref={clone} object={clone.current} />
    </group>
}