import { Html, useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Select } from '@react-three/postprocessing';
import React, { useEffect, useRef, useState } from 'react';
import { config, useSpring } from 'react-spring';
import { Group, MeshStandardMaterial, Object3DEventMap, Quaternion, Vector3 } from 'three';
import { DreiGLTF } from '../utils/types';
import Texting from '../routes/About/Texting';
import { isMobile } from 'react-device-detect';

interface PhoneProps{
    focus: boolean;
    onFocus: (v: boolean) => void;
}

interface PosRots{
    startPos: Vector3;
    startRot: Quaternion;
}

export default function Phone(props: PhoneProps){
    const [hover, setHover] = useState(false);
    const {nodes, materials, scene} = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/about me/phone.gltf`) as DreiGLTF;
    const [focus, setFocus] = useState(false);
    const {camera} = useThree();
    const groupRef = useRef<Group<Object3DEventMap>>(new Group<Object3DEventMap>());
    const posRotRef = useRef<PosRots>({
        startPos: new Vector3(2.40367, 1.09444, -6.6394),
        startRot: new Quaternion(-0.705, 0,0,1),
    })
    const [phoneMat, setPhoneMat] = useState(materials.phoneScreen);

    useEffect(() => {
        const interval = setInterval(() => {
            if (focus) return;
            const newMat = new MeshStandardMaterial({color: "#03fcbe"});
            setPhoneMat(newMat);
            setTimeout(() => {
                setPhoneMat(materials.phoneScreen);
            }, 200);
            setTimeout(() => {
                setPhoneMat(newMat);
            }, 400);
            setTimeout(() => {
                setPhoneMat(materials.phoneScreen);
            }, 600);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!props.focus && focus){
            s.position.start({from: groupRef.current.position.toArray(), to: posRotRef.current.startPos.toArray()});
            s.target.start({from: groupRef.current.quaternion.toArray(), to: posRotRef.current.startRot.toArray()});
        }
        setFocus(props.focus);
    }, [props.focus]);

    useEffect(() => {
        groupRef.current.position.copy(posRotRef.current.startPos);
        groupRef.current.quaternion.copy(posRotRef.current.startRot);
    }, [scene]);

    const s = useSpring({
        from: {
            position: [0, 0, 0],
            target: [0, 0, 0, 1]
        },
        config: config.default,
        onChange: (result) => {
            groupRef.current.position.set(result.value.position[0], result.value.position[1], result.value.position[2]);
            groupRef.current.quaternion.set(result.value.target[0], result.value.target[1], result.value.target[2], result.value.target[3]);
        }
    })

    function OnClick(){
        if (!focus) {
            const dist = isMobile ? 1 : 2;
            const cwd = new Vector3();
            camera.getWorldDirection(cwd);
            cwd.multiplyScalar(dist);
            cwd.add(camera.position);

            setFocus(true);
            props.onFocus(true);

            const testObj = groupRef.current.clone();
            testObj.position.copy(cwd);
            testObj.lookAt(camera.position.clone());
            const newRot = testObj.quaternion;
            s.position.start({from: groupRef.current.position.toArray(), to: cwd.toArray()});
            s.target.start({from: groupRef.current.quaternion.toArray(), to: newRot.toArray()});
        }
    }

    return <Select enabled={hover && !focus} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)} onClick={(e) => {e.stopPropagation(); OnClick()}}>
        <group ref={groupRef} dispose={null} position={scene.position} rotation={scene.rotation} scale={scene.scale}>
            <mesh geometry={nodes.Cube005.geometry} material={materials.phone} />
            <mesh geometry={nodes.Cube005_1.geometry} material={phoneMat}>
            {focus && <Html transform className='content' position={[0.025,0.18,0.02]} occlude distanceFactor={1}>
                <div className='wrapper'>
                    <Texting />
                </div>
            </Html>}
            </mesh>
        </group>
    </Select>
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/about me/phone.gltf`);