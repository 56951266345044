import { useGLTF } from '@react-three/drei';
import React from 'react';

export default function Land(){
    const obj = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/land.gltf`);

    obj.scene.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
    })

    return <primitive object={obj.scene} />
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/land.gltf`);