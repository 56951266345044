import React, { Suspense, useState } from 'react';
import '../../styles/App.css';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Scene from './Environment';
import { Perf } from 'r3f-perf'
import Lights from '../../utils/EnvironmentLights';
import { Vector3 } from 'three';
import { AnimateEyeToTarget } from '../../utils/AnimateCamera';
import { isMobile } from 'react-device-detect';

export interface AnimatedObject{
  focussed: string;
  run: boolean;
  animateProps: AnimateProperties;
  onAnimationFinished?: () => void;
}

interface AnimateProperties{
  to: Vector3;
  lookAt: Vector3;
}

export default function App() {
  const [animation, setAnimation] = useState<AnimatedObject>({focussed: "", run: false, animateProps: {to: new Vector3(), lookAt: new Vector3()}});

  function FocusCamera(obj: string, to: Vector3, lookAt: Vector3, onAnimationFinished?: () => void){
    if (obj !== animation.focussed){
      setAnimation({
        focussed: obj,
        run: true,
        onAnimationFinished: onAnimationFinished,
        animateProps: {
          to: to,
          lookAt: lookAt
        }
      });
    }
  }

  return (
    <>
    <Canvas className='background'
      shadows
      camera={{
        fov: isMobile ? 80 : 45,
        near: 0.1,
        far: 200,
        position: new Vector3(-15, 8, 35)
      }}>
        <OrbitControls makeDefault target={[-2,1,13]} enablePan={false} maxAzimuthAngle={Math.PI * 0.33} minAzimuthAngle={Math.PI * -0.17} 
          maxPolarAngle={Math.PI * 0.48} minPolarAngle={Math.PI * 0.2} minDistance={5} maxDistance={isMobile ? 33 : 25} />
        {process.env.REACT_APP_PERF === "true" && <Perf position='top-left' />}

        <Lights/>
        {animation.run && <AnimateEyeToTarget position={animation.animateProps.to} lookAt={animation.animateProps.lookAt} onAnimated={() => {
          const copy : AnimatedObject = {...animation};
          copy.run = false;
          setAnimation(copy);
          if (animation.onAnimationFinished) animation.onAnimationFinished();
        }} />}

        <Suspense fallback="Loading">
          <Scene setFocussed={FocusCamera} />
        </Suspense>
    </Canvas>
    </>
  );
}