import { OrbitControls, useGLTF } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Perf } from 'r3f-perf';
import React, { Suspense } from 'react';
import { Quaternion, Vector3 } from 'three';
import Polaroid from './Polaroid';
import { EffectComposer, Outline, Selection } from '@react-three/postprocessing';
import { useNavigate } from 'react-router-dom';
import OCRImg from './OCRIcon.png';
import IOTImg from './IOTIcon.png';
import Arrow from '../../models/Arrow';
import { isMobile } from 'react-device-detect';

export default function Projects(){
    const obj = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/projects/environment.gltf`);
    const navigate = useNavigate();

    return <>
    <div id='home' onClick={() => navigate("/")}><i className="fa-solid fa-door-open fa-2xl"></i></div>
    <Canvas camera={{
        fov: isMobile ? 80 : 45,
        near: 0.1,
        far: 200,
        position: new Vector3(-2.5, 3.6, 2.7)
        }}>
            <OrbitControls makeDefault target={[2.5,2.5,1.5]}
                minAzimuthAngle={Math.PI * -0.51} maxAzimuthAngle={Math.PI * -0.37}
                minPolarAngle={Math.PI * 0.35} maxPolarAngle={Math.PI * 0.6} minDistance={2} maxDistance={5.7}
                />
            {process.env.REACT_APP_PERF === "true" && <Perf position="top-right" />}
            {/* <Environment files={`${process.env.PUBLIC_URL}/blender/dist/projects/env.hdr`} background /> */}
            <directionalLight position={[-5,0,10]} />
            <ambientLight intensity={0.5} />

            <Suspense fallback={null}>
                <primitive object={obj.scene} />

                <Selection>
                <EffectComposer multisampling={0} autoClear={false}>
                    <Outline blur visibleEdgeColor={0xffffff} edgeStrength={100} width={1000} />
                </EffectComposer>

                <Arrow position={new Vector3(0.8, 2.6, 0.4)} scale={0.03} />
                <Polaroid route='ocr' imgIcon={OCRImg} position={new Vector3(0.8,2.53, 0.4)} />
                <Arrow position={new Vector3(4, 2.34, 1.7)} scale={0.03} />
                <Polaroid route='iot' imgIcon={IOTImg} position={new Vector3(4,2.27,1.7)} rotation={new Quaternion(-0.425853, -0.56449, -0.56449, 0.425853)} />
                </Selection>
            </Suspense>
    </Canvas>
    </>
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/projects/environment.gltf`);