import { OrbitControls, useGLTF } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Perf } from 'r3f-perf';
import React, { Suspense, useRef, useState } from 'react';
import { NoToneMapping, Vector3 } from 'three';
import Phone from '../../models/Phone';
import { Selection, EffectComposer, Outline } from '@react-three/postprocessing';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import "../../styles/Texting.css"
import { useNavigate } from 'react-router-dom';
import Arrow from '../../models/Arrow';
import Book from './Book';
import { isMobile } from 'react-device-detect';

export default function About() {
    const obj = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/about me/environment.gltf`);
    const orbitRef = useRef<OrbitControlsImpl>(null);
    const [phoneFocus, setPhoneFocus] = useState(false);
    const [bookFocus, setBookFocus] = useState(false);
    const navigate = useNavigate();
    const PhoneArrow = React.memo(() => <Arrow position={new Vector3(2.4,1.5,-6.5)} scale={0.1} />);
    const BookArrow = React.memo(() => <Arrow position={new Vector3(-2.8,0.2,-3)} scale={0.1} />);

    function OnPhoneFocus(val: boolean){
        setPhoneFocus(val);
        orbitRef.current!.enableRotate = !val;
        orbitRef.current!.enableZoom = !val;
    }

    function OnBookFocus(val: boolean){
        setBookFocus(val);
        orbitRef.current!.enableRotate = !val;
        orbitRef.current!.enableZoom = !val;
    }

    return <>
    <div id='home' onClick={() => navigate("/")}><i className="fa-solid fa-door-open fa-2xl"></i></div>
    <Canvas
        shadows
        gl={{antialias: true, toneMapping: NoToneMapping}}
        camera={{
        fov: isMobile ? 80 : 45,
        near: 0.1,
        far: 200,
        position: new Vector3(0, 3, 13)
        }}>
        <OrbitControls ref={orbitRef} makeDefault 
            maxAzimuthAngle={Math.PI * 0.1} minAzimuthAngle={Math.PI * -0.1} enablePan={false}
            maxPolarAngle={Math.PI * 0.55} minPolarAngle={Math.PI * 0.41} maxDistance={12} minDistance={8} 
            />
        {process.env.REACT_APP_PERF === "true" && <Perf position="top-right" />}

        <ambientLight intensity={10} />
        <Suspense fallback={null}>
            <group dispose={null} onClick={() => {
                OnPhoneFocus(false);
                OnBookFocus(false);
                }}>
                <primitive object={obj.scene} />
            </group>
            <PhoneArrow />
            <BookArrow />
            <Selection>
                <EffectComposer multisampling={0} autoClear={false}>
                <Outline blur visibleEdgeColor={0xffffff} edgeStrength={100} width={1000} />
                </EffectComposer>
                <Phone focus={phoneFocus} onFocus={OnPhoneFocus} />
                <Book focus={bookFocus} onFocus={OnBookFocus} />
            </Selection>
        </Suspense>
    </Canvas></>
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/about me/environment.gltf`);