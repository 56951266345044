import React, { useEffect, useState } from 'react';
import { ModelProps } from '../../utils/types';
import Instance from '../../utils/ModelInstances';
import GroupedObjects from '../../utils/GroupedObjects';
import { Object3D, Object3DEventMap } from 'three';
import Land from '../Land';

export default function Terrain(props: ModelProps){
    const {nodes, materials, ...remainingProps} = props;
    const [groupedObjects, setGroupedObjects] = useState<GroupedObjects>(new GroupedObjects());

    useEffect(() => {
        const newList = new GroupedObjects();
        nodes.land.children.forEach(c => {
            if (c.name.includes("tree")){
                const section = c.name.split("_")[0];
                switch(section){
                    case "tree2":
                        newList.tree1.push(c);
                        break;
                    case "tree1":
                        newList.tree2.push(c);
                        break;
                    case "tree1001":
                        newList.tree3.push(c);
                        break;
                    default:
                        newList.tree4.push(c);
                }
            }
            else if (c.name.includes("rock1")) newList.rock1.push(new Object3D<Object3DEventMap>().copy(c));
            else if (c.name.includes("rock2")) newList.rock2.push(new Object3D<Object3DEventMap>().copy(c));
            else if (c.name.includes("rock3")) newList.rock3.push(new Object3D<Object3DEventMap>().copy(c));
            else if (c.name.includes("bush002")) newList.bush1.push(new Object3D<Object3DEventMap>().copy(c));
            else if (c.name.includes("bush001")) newList.bush2.push(new Object3D<Object3DEventMap>().copy(c));
            else if (c.name.includes("grass001")) newList.grass1.push(new Object3D<Object3DEventMap>().copy(c));
            else if (c.name.includes("grass003")) newList.grass2.push(new Object3D<Object3DEventMap>().copy(c));
            else if (c.name.substring(0, 5) === "bush_") newList.bush3.push(new Object3D<Object3DEventMap>().copy(c));
        });
        setGroupedObjects(newList);
    }, [nodes])

    return <group {...remainingProps} dispose={null}>
        {/* <mesh castShadow receiveShadow geometry={nodes.land.geometry} material={materials.terrain} /> */}
        <Land />

        {/* Tree 1 */}
        <Instance objects={groupedObjects.tree1} 
            meshes={[{geo:nodes.Cylinder003_1.geometry, mat:materials["tree_leaves_1.001"]}, {geo:nodes.Cylinder003.geometry, mat: materials.tree_bark_1}]} />
        
        {/* Tree 2 */}
        <Instance objects={groupedObjects.tree2} 
            meshes={[{geo:nodes.Cylinder001_1.geometry, mat:materials["tree_leaves_3"]}, {geo:nodes.Cylinder003.geometry, mat: materials.tree_bark_1}]} />

        {/* Tree 3 */}
        <Instance objects={groupedObjects.tree3}
            meshes={[{geo:nodes.Cylinder001_1.geometry, mat:materials["tree_leaves_2"]}, {geo:nodes.Cylinder003.geometry, mat: materials.tree_bark_1}]} />

        {/* Tree 4 */}
        <Instance objects={groupedObjects.tree4}
            meshes={[{geo:nodes.Cylinder001_1.geometry, mat:materials["tree_leaves_1"]}, {geo:nodes.Cylinder003.geometry, mat: materials.tree_bark_1}]} />

        {/* Rock 1 */}
        <Instance objects={groupedObjects.rock1} 
            meshes={[{geo:nodes.rock1.geometry, mat:materials.rock}]} />

        {/* Rock 2 */}
        <Instance objects={groupedObjects.rock2} 
            meshes={[{geo:nodes.rock2.geometry, mat:materials.rock2}]} />

        {/* Rock 3 */}
        <Instance objects={groupedObjects.rock3} 
            meshes={[{geo:nodes.rock3.geometry, mat:materials.rock3}]} />

        {/* Bush 1 */}
        <Instance objects={groupedObjects.bush1} 
            meshes={[{geo:nodes.bush002.geometry, mat:materials.bush3}]} />

        {/* Bush 2 */}
        <Instance objects={groupedObjects.bush2} 
            meshes={[{geo:nodes.bush001.geometry, mat:materials.bush2}]} />

        {/* Bush 3 */}
        <Instance objects={groupedObjects.bush3} 
            meshes={[{geo:nodes.bush.geometry, mat:materials.bush}]} />

        {/* Grass 1 */}
        <Instance objects={groupedObjects.grass1} 
            meshes={[{geo:nodes.grass001.geometry, mat:materials.grass}]} />

        {/* Grass 2 */}
        <Instance objects={groupedObjects.grass2} 
            meshes={[{geo:nodes.grass003.geometry, mat:materials.grass2}]} />
    </group>
}