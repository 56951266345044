import React from "react";
import { useGLTF } from "@react-three/drei";

export default function Logs(){
    const obj = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/logs.gltf`);
    obj.scene.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
    })
    return <primitive object={obj.scene} />
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/logs.gltf`);