import { useTexture } from "@react-three/drei";
import { SkinnedMeshProps, useFrame, useThree } from "@react-three/fiber";
import { easing } from "maath";
import { useEffect, useMemo, useRef, useState } from "react";
import { Select } from '@react-three/postprocessing';
import {
  Bone,
  BoxGeometry,
  Color,
  Float32BufferAttribute,
  Group,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3DEventMap,
  Skeleton,
  SkinnedMesh,
  SRGBColorSpace,
  Uint16BufferAttribute,
  Vector3,
} from "three";
import { degToRad } from "three/src/math/MathUtils.js";
import { pages } from "./Pages";
import { config, useSpring } from "react-spring";
import { isMobile } from "react-device-detect";

const easingFactor = 0.5; // Controls the speed of the easing
const easingFactorFold = 0.3; // Controls the speed of the easing
const insideCurveStrength = 0.18; // Controls the strength of the curve
const outsideCurveStrength = 0.05; // Controls the strength of the curve
const turningCurveStrength = 0.09; // Controls the strength of the curve

const PAGE_WIDTH = 1.28;
const PAGE_HEIGHT = 1.71; // 4:3 aspect ratio
const PAGE_DEPTH = 0.003;
const PAGE_SEGMENTS = 30;
const SEGMENT_WIDTH = PAGE_WIDTH / PAGE_SEGMENTS;

const pageGeometry = new BoxGeometry(
  PAGE_WIDTH,
  PAGE_HEIGHT,
  PAGE_DEPTH,
  PAGE_SEGMENTS,
  2
);

pageGeometry.translate(PAGE_WIDTH / 2, 0, 0);

const position = pageGeometry.attributes.position;
const vertex = new Vector3();
const skinIndexes = [];
const skinWeights = [];

for (let i = 0; i < position.count; i++) {
  // ALL VERTICES
  vertex.fromBufferAttribute(position, i); // get the vertex
  const x = vertex.x; // get the x position of the vertex

  const skinIndex = Math.max(0, Math.floor(x / SEGMENT_WIDTH)); // calculate the skin index
  let skinWeight = (x % SEGMENT_WIDTH) / SEGMENT_WIDTH; // calculate the skin weight

  skinIndexes.push(skinIndex, skinIndex + 1, 0, 0); // set the skin indexes
  skinWeights.push(1 - skinWeight, skinWeight, 0, 0); // set the skin weights
}

pageGeometry.setAttribute(
  "skinIndex",
  new Uint16BufferAttribute(skinIndexes, 4)
);
pageGeometry.setAttribute(
  "skinWeight",
  new Float32BufferAttribute(skinWeights, 4)
);

const whiteColor = new Color("white");

const pageMaterials = [
  new MeshStandardMaterial({
    color: whiteColor,
  }),
  new MeshStandardMaterial({
    color: "#111",
  }),
  new MeshStandardMaterial({
    color: whiteColor,
  }),
  new MeshStandardMaterial({
    color: whiteColor,
  }),
];

pages.forEach((page) => {
  useTexture.preload(`${process.env.PUBLIC_URL}/textures/${page.front}`);
  useTexture.preload(`${process.env.PUBLIC_URL}/textures/${page.back}`);
});

interface PageProps{
    number: number;
    front: string;
    back: string;
    page: number;
    opened: boolean;
    bookClosed: boolean;
    enabled: boolean;
    setPage: (x: number) => void;
}

function Page(props: PageProps) {
  const [picture, picture2] = useTexture([
    `${process.env.PUBLIC_URL}/textures/${props.front}`,
    `${process.env.PUBLIC_URL}/textures/${props.back}`,
  ]);
  picture.colorSpace = picture2.colorSpace = SRGBColorSpace;
  const group = useRef<Group<Object3DEventMap>>(null);
  const turnedAt = useRef(0);
  const lastOpened = useRef(props.opened);

  const skinnedMeshRef = useRef<SkinnedMeshProps>({});

  const manualSkinnedMesh = useMemo(() => {
    const bones = [];
    for (let i = 0; i <= PAGE_SEGMENTS; i++) {
      let bone = new Bone();
      bones.push(bone);
      if (i === 0) {
        bone.position.x = 0;
      } else {
        bone.position.x = SEGMENT_WIDTH;
      }
      if (i > 0) {
        bones[i - 1].add(bone); // attach the new bone to the previous bone
      }
    }
    const skeleton = new Skeleton(bones);

    const materials = [
      ...pageMaterials,
      new MeshBasicMaterial({
        color: whiteColor,
        map: picture,
      }),
      new MeshBasicMaterial({
        color: whiteColor,
        map: picture2,
      }),
    ];
    const mesh = new SkinnedMesh(pageGeometry, materials);
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    mesh.frustumCulled = false;
    mesh.add(skeleton.bones[0]);
    mesh.bind(skeleton);
    return mesh;
  }, []);

  useFrame((_, delta) => {
    if (!skinnedMeshRef.current) {
      return;
    }

    if (lastOpened.current !== props.opened) {
      turnedAt.current = +new Date();
      lastOpened.current = props.opened;
    }
    
    let turningTime = Math.min(400, new Date().getTime() - turnedAt.current) / 400;
    turningTime = Math.sin(turningTime * Math.PI);

    let targetRotation = props.opened ? -Math.PI / 2 : Math.PI / 2;
    if (!props.bookClosed) {
      targetRotation += degToRad(props.number * 0.8);
    }


    const bones = skinnedMeshRef.current.skeleton ? skinnedMeshRef.current.skeleton.bones : [];
    for (let i = 0; i < bones.length; i++) {
      const target = i === 0 ? group.current : bones[i];

      const insideCurveIntensity = i < 8 ? Math.sin(i * 0.2 + 0.25) : 0;
      const outsideCurveIntensity = i >= 8 ? Math.cos(i * 0.3 + 0.09) : 0;
      const turningIntensity =
        Math.sin(i * Math.PI * (1 / bones.length)) * turningTime;
      let rotationAngle =
        insideCurveStrength * insideCurveIntensity * targetRotation -
        outsideCurveStrength * outsideCurveIntensity * targetRotation +
        turningCurveStrength * turningIntensity * targetRotation;
      let foldRotationAngle = degToRad(Math.sign(targetRotation) * 2);
      if (props.bookClosed) {
        if (i === 0) {
          rotationAngle = targetRotation;
          foldRotationAngle = 0;
        } else {
          rotationAngle = 0;
          foldRotationAngle = 0;
        }
      }
      if (target) easing.dampAngle(
        target.rotation,
        "y",
        rotationAngle,
        easingFactor,
        delta
      );

      const foldIntensity =
        i > 8
          ? Math.sin(i * Math.PI * (1 / bones.length) - 0.5) * turningTime
          : 0;
      if (target) easing.dampAngle(
        target.rotation,
        "x",
        foldRotationAngle * foldIntensity,
        easingFactorFold,
        delta
      );
    }
  });

  return (
    <group
      ref={group}
      onPointerEnter={(e) => {
        e.stopPropagation();
      }}
      onPointerLeave={(e) => {
        e.stopPropagation();
      }}
      onClick={(e) => {
        console.log(props.enabled);
        if (!props.enabled) return;
        console.log("Clicked");
        e.stopPropagation();
        props.setPage(props.opened ? props.number : props.number + 1);
      }}
    >
      <primitive
        object={manualSkinnedMesh}
        ref={skinnedMeshRef}
        position-z={-props.number * PAGE_DEPTH + props.page * PAGE_DEPTH}
      />
    </group>
  );
};

interface BookProps{
  focus: boolean;
  onFocus: (v: boolean) => void;
}

interface PosRots{
  startPos: Vector3;
  startRot: Vector3;
}

export default function Book(props: BookProps) {
    const [page, setPage] = useState(0);
    const [delayedPage, setDelayedPage] = useState(page);
    const [hover, setHover] = useState(false);
    const [focus, setFocus] = useState(false);
    const {camera} = useThree();
    const groupRef = useRef<Group<Object3DEventMap>>(new Group<Object3DEventMap>());

    const posRotRef = useRef<PosRots>({
      startPos: new Vector3(-3.5,-0.2,-3),
      startRot: new Vector3(-Math.PI / 2, -Math.PI/2, 0),
    })

    useEffect(() => {
        let timeout : NodeJS.Timeout;
        const goToPage = () => {
        setDelayedPage((delayedPage) => {
            if (page === delayedPage) {
                return delayedPage;
            } 
            else {
                timeout = setTimeout(() => goToPage(), Math.abs(page - delayedPage) > 2 ? 50 : 150);
                if (page > delayedPage) {
                    return delayedPage + 1;
                }
                else {
                    return delayedPage - 1;
                }
            }
        });
        };
        goToPage();
        return () => {
        clearTimeout(timeout);
        };
    }, [page]);

    useEffect(() => {
      if (!props.focus && focus){
          s.position.start({from: groupRef.current.position.toArray(), to: posRotRef.current.startPos.toArray()});
          s.target.start({from: groupRef.current.quaternion.toArray(), to: posRotRef.current.startRot.toArray()});
          setPage(0);
      }
      setFocus(props.focus);
    }, [props.focus]);

    useEffect(() => {
      groupRef.current.position.set(-3.5,-0.2,-3);
      groupRef.current.rotation.set(-Math.PI / 2, -Math.PI/2, 0);
    }, [groupRef]);

    const s = useSpring({
      from: {
          position: [0, 0, 0],
          target: [0, 0, 0, 1]
      },
      config: config.default,
      onChange: (result) => {
          groupRef.current.position.set(result.value.position[0], result.value.position[1], result.value.position[2]);
          groupRef.current.rotation.set(result.value.target[0], result.value.target[1], result.value.target[2]);
      }
    })

    function OnClicked(){
      if (!focus) {
        const dist = isMobile ? 3 : 2.8;
        const cwd = new Vector3();
        camera.getWorldDirection(cwd);
        cwd.multiplyScalar(dist);
        cwd.add(camera.position);

        setFocus(true);
        props.onFocus(true);

        const testObj = groupRef.current.clone();
        testObj.position.copy(cwd);
        testObj.lookAt(camera.position.clone());
        const newRot : number[] = [];
        testObj.rotation.toArray(newRot);
        newRot[1] += -Math.PI/2;
        const oldRot : number[] = [];
        groupRef.current.rotation.toArray(oldRot);
        const newPos : number[] = cwd.toArray();

        s.position.start({from: groupRef.current.position.toArray(), to: newPos});
        s.target.start({from: oldRot, to: newRot});
      }
    }

    return (
      <Select enabled={hover && !focus} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)} onClick={(e) => {if (!focus) e.stopPropagation(); OnClicked()}}>
        <group ref={groupRef}>
        {[...pages].map((pageData, index) => (
            <Page
            enabled={focus}
            key={index}
            page={delayedPage}
            number={index}
            opened={delayedPage > index}
            bookClosed={delayedPage === 0 || delayedPage === pages.length}
            {...pageData}
            setPage={focus ? setPage : () => {}}
            />
        ))}
        <mesh position={[0,0,-0.65]} scale={[0.05, 1.7, 1.3]}>
          <boxGeometry />
          <meshStandardMaterial opacity={0} transparent />
        </mesh>
        </group>
        </Select>
    );
};