import { useThree } from "@react-three/fiber";
import { useMemo, useState } from "react";
import { config, useSpring } from "react-spring";
import { Vector3 } from "three";
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';

interface AnimateProps{
    position: Vector3;
    lookAt: Vector3;
    onAnimated: () => void;
    enableCameraAtEnd?: boolean;
}

export function AnimateEyeToTarget(props: AnimateProps) {
    const { controls, camera } = useThree();
    const orbitControls = useMemo(() => controls as OrbitControlsImpl, [controls]);
    const [run, setRun] = useState(true);

    const s = useSpring({
      from: {
      position: [0, 0, 0],
      target: [0, 0, 0]
      },
      config: config.default,
      onStart: () => {
        orbitControls.enabled = false;
      },
      onChange: (result) => {
        camera.position.set(result.value.position[0], result.value.position[1], result.value.position[2]);
        orbitControls.target.set(result.value.target[0], result.value.target[1], result.value.target[2]);
        orbitControls.update();
        camera.updateProjectionMatrix();
      },
      onRest: () => {
        setRun(false);
        props.onAnimated();
        if (props.enableCameraAtEnd) orbitControls.enabled = true;
      },
    });

    if (run){
      s.position.start({ from: camera.position.toArray(), to: props.position.toArray() });
      s.target.start({ from: orbitControls.target.toArray(), to: props.lookAt.toArray() });
    }

    return <></>
  }