import React from 'react';
import { useGLTF } from '@react-three/drei'
import { GroupProps } from '@react-three/fiber';
import TeePee from '../../models/TeePee';
import Tent from '../../models/Tent';
import Campfire from '../../models/Campfire';
import { DreiGLTF } from '../../utils/types';
import Logs from '../../models/groups/Logs';
import { Vector3 } from 'three';
import { Selection, EffectComposer, Outline } from '@react-three/postprocessing';
import Terrain from '../../models/groups/Terrain';
import Campervan from '../../models/Campervan';

interface SceneProps extends GroupProps{
    setFocussed: (obj: string, to: Vector3, lookAt: Vector3, onAnimationFinished?: () => void) => void;
}

export default function Scene(props: SceneProps)
{
    const {nodes, materials, scene } = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/environment.gltf`) as DreiGLTF;

    scene.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
    })

    return <group receiveShadow castShadow dispose={null}>
        <Selection>
            <EffectComposer multisampling={0} autoClear={false}>
            <Outline blur visibleEdgeColor={0xffffff} edgeStrength={100} width={1000} />
            </EffectComposer>
            <TeePee focus={props.setFocussed} />
            <Tent focus={props.setFocussed} />
            <Campervan focus={props.setFocussed} />
        </Selection>
        <Campfire focus={() => {}} />
        <Terrain nodes={nodes} materials={materials} />
        <Logs />
    </group>
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/environment.gltf`);