import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './routes/Home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './routes/About/About';
import Texting from './routes/About/Texting';
import Projects from './routes/Projects/Projects';
import OCR from './routes/Projects/OCR/OCR';
import IOT from './routes/Projects/IOT/IOT';
import Loading, { LoadingWithBar } from './routes/Loading';
import Career from './routes/Career/Career';
import Test from './TestFile';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<App />} />
          <Route path='loading' element={<Loading />} />
          <Route path='about' element={<About />} />
          <Route path='aboutContent' element={<Texting />} />
          <Route path='projects'>
            <Route index element={<Projects />} />
            <Route path='ocr' element={<OCR />} />
            <Route path='iot' element={<IOT />} />
          </Route>
          <Route path="career" element={<Career />} />
        </Route>
      </Routes>
      <LoadingWithBar />
    </BrowserRouter>
  </React.StrictMode>
);
// root.render(<Test />);