import React from 'react';
import ChirpstackSim from './ChirpstackSim';
import { Group, Object3DEventMap } from 'three';
import { Html } from '@react-three/drei';

interface ScreenProps{
    nodes: Record<string, THREE.Mesh>;
    materials: Record<string, THREE.MeshStandardMaterial>;
    scene: Group<Object3DEventMap>;
}

export default function Screen(props: ScreenProps){

    return <group position={props.scene.position} rotation={props.scene.rotation} scale={props.scene.scale} dispose={null}>
        <mesh geometry={props.nodes.Screen.geometry} position={props.nodes.Screen.position}>
            <meshBasicMaterial/>
            <Html transform occlude distanceFactor={1} rotation-x={Math.PI * 1.975} 
                position={[0,0.005,0.03]} wrapperClass='screenWrapper'>
                <ChirpstackSim />
            </Html>
        </mesh>
    </group>
}