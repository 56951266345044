import React, { useRef } from 'react';
import { Color, DirectionalLight, OrthographicCamera, Vector2, Vector3 } from 'three';

export default function Lights(){
    const sunlight = useRef<DirectionalLight>(new DirectionalLight());
    const shadowCam = useRef<OrthographicCamera>(sunlight.current.shadow.camera);

    sunlight.current.position.copy(new Vector3(12,10,25));
    sunlight.current.intensity = 5;
    sunlight.current.shadow.mapSize = new Vector2(512,512);
    sunlight.current.color = new Color("#c76900");
    sunlight.current.shadow.bias = -0.0001;

    shadowCam.current.left = -60;
    shadowCam.current.right = 60;
    shadowCam.current.top = 20;
    shadowCam.current.bottom = -10;
    shadowCam.current.updateProjectionMatrix();

    return <>
        <directionalLight castShadow position={[12,10,25]} intensity={5} shadow-mapSize={[4096, 4096]} scale={0.125} color={"#c76900"} 
            shadow-bias={0.00001} shadow-normalBias={0.4} shadow-radius={2}
            shadow-camera-left={-60} shadow-camera-right={60} shadow-camera-top={20} shadow-camera-bottom={-10}/>
        <ambientLight intensity={0.3} />
    </>
}