import React, { createRef, useEffect, useMemo } from "react";
import { Object3D, Object3DEventMap, BufferGeometry, NormalBufferAttributes, MeshStandardMaterial, InstancedMesh, Material } from "three";

interface InstanceProps{
    objects: Object3D<Object3DEventMap>[];
    meshes: GeoMat[];
}

interface GeoMat{
    geo: BufferGeometry<NormalBufferAttributes>;
    mat: MeshStandardMaterial;
}

export default function Instance(props: InstanceProps){
    const temp = useMemo(() => new Object3D(), []);
    const meshRefs = props.meshes.map(m => createRef<InstancedMesh<BufferGeometry<NormalBufferAttributes>, Material | Material[]>>());
    const allRefs = useMemo(() => meshRefs.map(m => m.current), [meshRefs]);

    useEffect(() => {
        if (meshRefs.some(r => !r.current)) return;

        for (let i = 0; i < props.objects.length; i++){
            temp.position.set(props.objects[i].position.x, props.objects[i].position.y, props.objects[i].position.z);
            temp.rotation.set(props.objects[i].rotation.x, props.objects[i].rotation.y, props.objects[i].rotation.z);
            temp.scale.set(props.objects[i].scale.x, props.objects[i].scale.y, props.objects[i].scale.z);
            temp.updateMatrix();
            meshRefs.forEach(r => r.current!.setMatrixAt(i, temp.matrix));
        }
        meshRefs.forEach(r => r.current!.instanceMatrix.needsUpdate = true);
    }, [props.objects, allRefs, meshRefs, temp]);

    return <group dispose={null}>
        {props.meshes.map((m, idx) => <instancedMesh key={idx} ref={meshRefs[idx]} args={[m.geo, m.mat, props.objects.length]} receiveShadow castShadow />)}
    </group>
}