import { useProgress } from "@react-three/drei";
import React from "react";
import LoadingBar from "react-top-loading-bar";

export default function Loading(){
  return <div className="videoContainer"><center><video autoPlay muted loop className="videoInsert">
        <source src={`${process.env.PUBLIC_URL}/Intro.mp4`} type="video/mp4"></source>
  </video></center>
  </div>
}

export function LoadingWithBar(){
  const {progress} = useProgress();

  //if (progress >= 100) return <></>

  return <div className={"videoContainer " + (progress < 100 ? "loading-screen-active" : "loading-screen-ended")}><center><video autoPlay muted loop className="videoInsert">
        <source src={`${process.env.PUBLIC_URL}/Intro.mp4`} type="video/mp4"></source>
  </video></center>
  <LoadingBar progress={progress} />
  </div>
}