import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useRef } from "react";
import { AnimationMixer } from "three";
import { DreiGLTF } from "../../../utils/types";

export default function Environment(){
    const {animations, scene} = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/projects/OCR/OCR.gltf`) as DreiGLTF;
    const mixer = useRef(new AnimationMixer(scene));

    if (animations.length){
        animations.forEach(clip => {
            const action = mixer.current.clipAction(clip);
            action.play();
        })
    }

    useFrame((_, delta) => {
        mixer.current.update(delta);
    });

    return <primitive object={scene} />
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/projects/OCR/OCR.gltf`);