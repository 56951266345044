import { Object3D, Object3DEventMap } from "three";

export default class GroupedObjects{
    tree1: Object3D<Object3DEventMap>[];
    tree2: Object3D<Object3DEventMap>[]; 
    tree3: Object3D<Object3DEventMap>[];
    tree4: Object3D<Object3DEventMap>[];
    rock1: Object3D<Object3DEventMap>[];
    rock2: Object3D<Object3DEventMap>[];
    rock3: Object3D<Object3DEventMap>[];
    bush1: Object3D<Object3DEventMap>[];
    bush2: Object3D<Object3DEventMap>[];
    bush3: Object3D<Object3DEventMap>[];
    grass1: Object3D<Object3DEventMap>[];
    grass2: Object3D<Object3DEventMap>[];

    constructor(){
        this.tree1 = [];
        this.tree2 = [];
        this.tree3 = [];
        this.tree4 = [];
        this.rock1 = [];
        this.rock2 = [];
        this.rock3 = [];
        this.bush1 = [];
        this.bush2 = [];
        this.bush3 = [];
        this.grass1 = [];
        this.grass2 = [];
    }
}