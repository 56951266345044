import { ContactShadows, OrbitControls, useGLTF } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Perf } from 'r3f-perf';
import React, { Suspense, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, config } from 'react-spring';
import { Vector2, Vector3 } from 'three';
import { CapgeminiDescription, SchoolDescription } from './Paragraphs';
import { isMobile } from 'react-device-detect';

const paragraphs = [
    <SchoolDescription />,
    <CapgeminiDescription />
]

export default function Career(){
    const [buttonsEnabled, setButtonsEnabled] = useState(true);
    const [groupRotation, setGroupRotation] = useState(0);
    const navigate = useNavigate();
    const schoolObj = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/career/School.gltf`);
    const officeObj = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/career/Office.gltf`);

    const circleRadius = 3;
    const numOfObjects = 2;
    const angleChange = (Math.PI * 2) / numOfObjects;

    const circlePositions : Vector2[] = GetCirclePositions();
    const [selectedObject, setSelectedObject] = useState(0);

    const s = useSpring({
        from: {
            angle: 0
        },
        config: config.gentle,
        onStart: () => {
            setButtonsEnabled(false);
        },
        onChange: (result) => {
            setGroupRotation(result.value.angle);
        },
        onRest: () => {
            setButtonsEnabled(true);
            setGroupRotation(angleChange * selectedObject);
        }
    });

    function GetCirclePositions() : Vector2[]{
        const final : Vector2[] = [];
        for (let i = 0; i < numOfObjects; i++){
            final.push(
                new Vector2(circleRadius * Math.cos(angleChange * i), circleRadius * Math.sin(angleChange * i))
            );
        }
        return final; 
    }

    function OnArrowClicked(direction: number){
        if (!buttonsEnabled) return;
        let newNum = selectedObject - direction;
        let addition = 0;
        if (newNum >= numOfObjects) {
            newNum = 0;
            addition = Math.PI * 2;
        }
        else if (newNum < 0) {
            newNum = numOfObjects - 1;
            addition = -Math.PI * 2;
        }

        setSelectedObject(newNum);
        s.angle.start({from: groupRotation, to: angleChange * newNum + addition});
    }

    return <>
    <div id='home' onClick={() => navigate("/")}><i className="fa-solid fa-door-open fa-2xl"></i></div>
    <Canvas camera={{
        fov: 45,
        near: 0.1,
        far: 200,
        position: new Vector3(10.5, 2, 0)
        }}>
            <color args={ [ '#c4c4c4' ] } attach="background" />
            <OrbitControls makeDefault target={[0,0,0]}
                minPolarAngle={Math.PI * 0.3} maxPolarAngle={Math.PI * 0.45} minDistance={8} maxDistance={isMobile ? 14 : 10.5}
                minAzimuthAngle={Math.PI * 0.4} maxAzimuthAngle={Math.PI * 0.6} enablePan={false}
                />
            {process.env.REACT_APP_PERF === "true" && <Perf position="bottom-left" />}
            <ambientLight intensity={1} />
            <directionalLight intensity={1} position={[10, 1, 3]} castShadow />
            <Suspense fallback={null}>
                <group rotation={[0,groupRotation,0]}>
                    {/*                                                                                                 -angleChange * positonInCircle + (Math.PI * 0.5) */}
                    <group scale={[0.2,0.2,0.2]} position={[circlePositions[0].x,0,circlePositions[0].y]} rotation={[0, -angleChange * 0 + (Math.PI * 0.5), 0]}>
                        <primitive object={schoolObj.scene} />
                    </group>
                    <group scale={[0.4,0.4,0.4]} position={[circlePositions[1].x,0,circlePositions[1].y]} rotation={[0, -angleChange * 1 + (Math.PI * 0.5),0]}>
                        <primitive object={officeObj.scene} />
                    </group>
                </group>
            </Suspense>
            <ContactShadows width={10} height={10} far={100} rotation={[Math.PI / 2, 0, 0]} opacity={1.5} />
    </Canvas>
    <div className='selector' style={{right: isMobile ? "10vw" : "4vw"}} onClick={() => OnArrowClicked(1)}>
        <i className={"fa-solid fa-arrow-right " + (isMobile ? "" : "fa-2xl")}></i>
    </div>
    <div className='selector' style={{left: isMobile ? "10vw" : "4vw"}} onClick={() => OnArrowClicked(-1)}>
        <i className={"fa-solid fa-arrow-left " + (isMobile ? "" : "fa-2xl")}></i>
    </div>
    <div className={`career-description${isMobile ? "-mobile" : ""}`}>
        {paragraphs[selectedObject]}
    </div>
    </>
}