import React, { useRef, useState } from "react";
import { AnimateProps } from "../utils/types";
import { useGLTF, Text3D } from "@react-three/drei";
import { MeshBasicMaterial, Vector3 } from "three";
import { Select } from "@react-three/postprocessing";
import { useNavigate } from "react-router-dom";

export default function Campervan(props: AnimateProps){
    const textMaterial = useRef<MeshBasicMaterial>(new MeshBasicMaterial({color: "#42f5c2"}));
    const [hover, setHover] = useState(false);

    const pos = new Vector3(1,1.5,11);
    const lookAt = new Vector3(-1,3,5);

    const navigate = useNavigate();

    const obj = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/campervan.gltf`);
    obj.scene.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
    })

    function OnAnimationFinished(){
        navigate("/projects")
    }

    return <Select enabled={hover} onPointerDown={() => props.focus("campervan", pos, lookAt, OnAnimationFinished)}>
        <group dispose={null} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)}>
            <Text3D position={[-3, 1.2, 7]} rotation-y={0.35} scale-z={0.4} font={`${process.env.PUBLIC_URL}/Agbalumo_Regular.json`}
                 material={textMaterial.current} >
                Projects
            </Text3D>
            <primitive object={obj.scene} />
        </group>
    </Select>
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/campervan.gltf`);