import React, { useEffect, useState } from 'react';
import '../../../styles/Chirpstack.css'

interface ReadingProps{
    name: string;
    value: number;
    alert?: boolean;
}

export default function ChirpstackSim(){
    const [readings, setReadings] = useState<ReadingProps[]>([]);

    useEffect(() => {
        const interval = setInterval(() => {
            AddNewReading();
        }, 3000)

        return () => clearInterval(interval);
    }, []);

    function GetRandomValue(min: number, max: number){
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function AddNewReading(){
        const val = GetRandomValue(0, 40);
        const newReading : ReadingProps = {
            name: "Pressure Sensor 1",
            value: val,
            alert: val < 5
        };
        setReadings(prevReadings => [...prevReadings, newReading]);
    }

    return <div className='screen'>
        <div className='header row'>
            <HeaderButton text='Home' />
            <HeaderButton text='Gateways' />
            <HeaderButton text='Devices' selected />
            <HeaderButton text='Organisation' />
        </div>
        <div className='chirpstack row'>
            <hr />
            <div className='sidebar col-3'>
                <SideNav deviceName='Device 1' />
                <SideNav deviceName='Device 2' />
                <SideNav deviceName='Pressure Sensor 1' selected />
                <SideNav deviceName='Device 3' />
                <div className='profile mb-4'>
                    Profile
                </div>
            </div>
            <div className='main col'>
                <div className='title'>
                    Pressure Sensor Data Stream
                </div>
                <div className='stream'>
                    {[...readings].reverse().map((r, idx) => <Reading key={idx} reading={r} />)}
                </div>
            </div>
        </div>
    </div>
}

function HeaderButton(props: {text: string, selected?: boolean}){
    return <div className={`headerText col` + (props.selected ? " active" : "")}>
        {props.text}
    </div>
}

function SideNav(props: {deviceName: string, selected?: boolean}){
    return <div className={`sideNav ms-auto` + (props.selected ? " active" : "")}>
        {props.deviceName}
    </div>
}

function Reading(props: {reading: ReadingProps}){
    return <div className={`reading` + (props.reading.alert ? " readingError" : "")}>
        <div className='name'>{props.reading.name}</div>
        <div className='value ms-auto me-5'>{!!props.reading.alert && <i className="fa-solid fa-triangle-exclamation me-3" style={{color:"red"}}></i>}
            Value: {props.reading.value}</div>
    </div>
}