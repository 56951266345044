export interface PageProps{
    front: string;
    back: string;
}

export const pages : PageProps[] = [
    {
        front: "BookCover_Front.jpg",
        back: "UnityPage1.png"
    },
    {
        front: "UnityPage2.png",
        back: "BlenderPage1.png"
    },
    {
        front: "BlenderPage2.png",
        back: "SoftballPage1.png"
    },
    {
        front: "SoftballPage2.png",
        back: "BookCover_Back.jpg"
    }
]