import { useGLTF } from "@react-three/drei";
import React, { useRef, useState } from "react";
import { Select } from '@react-three/postprocessing';
import { config, useSpring } from "react-spring";
import { Group, MeshStandardMaterial, Object3DEventMap, Quaternion, TextureLoader, Vector3 } from "three";
import { useLoader, useThree } from "@react-three/fiber";
import { DreiGLTF } from "../../utils/types";
import { useNavigate } from "react-router-dom";

interface PolaroidProps{
    route?: string;
    imgIcon: string;
    position?: Vector3;
    rotation?: Quaternion;
}

export default function Polaroid(props: PolaroidProps){
    const {nodes, materials} = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/projects/polaroid.gltf`) as DreiGLTF;
    const img = useLoader(TextureLoader, props.imgIcon);
    const [hover, setHover] = useState(false);
    const {camera} = useThree();
    const groupRef = useRef<Group<Object3DEventMap>>(new Group<Object3DEventMap>());
    const navigate = useNavigate();

    const imgMat = new MeshStandardMaterial({map: img});


    const s = useSpring({
        from: {
            position: [0, 0, 0],
            target: [0, 0, 0, 1]
        },
        config: config.default,
        onChange: (result) => {
            groupRef.current.position.set(result.value.position[0], result.value.position[1], result.value.position[2]);
            groupRef.current.quaternion.set(result.value.target[0], result.value.target[1], result.value.target[2], result.value.target[3]);
        },
        onRest: (e) => {
            if (e.finished && props.route) navigate(props.route);
        }
    })

    function OnClick(){
        const dist = 0.4;
        const cwd = new Vector3();
        camera.getWorldDirection(cwd);
        cwd.multiplyScalar(dist);
        cwd.add(camera.position);

        const testObj = groupRef.current.clone();
        testObj.position.copy(cwd);
        testObj.lookAt(camera.position.clone());
        const newRot = testObj.quaternion;
        s.position.start({from: groupRef.current.position.toArray(), to: cwd.toArray()});
        s.target.start({from: groupRef.current.quaternion.toArray(), to: newRot.toArray()});
    }

    return <Select enabled={hover} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)} onClick={OnClick}>
        <group ref={groupRef} dispose={null} position={props.position ? props.position : nodes.Plane.position} 
            quaternion={props.rotation ? props.rotation :nodes.Plane.quaternion} scale={nodes.Plane.scale}>
            <mesh geometry={nodes.Plane001.geometry} material={materials.polaroid} />
            <mesh geometry={nodes.Plane001_1.geometry} material={imgMat} />
        </group>
        </Select>
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/projects/polaroid.gltf`);