import React, { useEffect, useRef, useState } from "react";
import '../../styles/Texting.css';
import 'bootstrap/dist/css/bootstrap.css';
import DotsLoader from "../../utils/DotsLoader";
import { useClock } from "react-timing-hooks";
import '../../styles/Dropup.css';
import { isMobile } from "react-device-detect";

export default function Texting(){
    const [messages, setMessages] = useState<MessageInfo[]>([{isOwner:false, msg:"Hello! Please click the message boxes to talk to me.", key: -1}]);
    const historyRef = useRef<HTMLDivElement>(null);
    const [lastMsg, setLastMsg] = useState<MessageInfo>(messages[0]);
    const [typing, setTyping] = useState(false);
    const [currentTime] = useClock();
    const [showQuestions, setShowQuestions] = useState(false);

    function AddQuestion(val: string, key: number){
        console.log("Adding message");
        const copy = [...messages];
        const newMsg : MessageInfo = {isOwner: true, msg: val, key: key};
        copy.push(newMsg);
        setMessages(copy);
        if (key > 0) setTyping(true);
        setShowQuestions(false);
        setTimeout(() => {
            setTyping(false);
            setLastMsg(newMsg);
        }, 3000);
    }

    useEffect(() => {
        historyRef.current?.scrollTo({top: historyRef.current.scrollHeight});
    }, [messages, historyRef.current?.scrollHeight]);

    useEffect(() => {
        if (lastMsg.isOwner){
            const copy = [...messages];
            switch(messages[messages.length - 1].key){
                case 1:
                    copy.push({isOwner: false, msg: "My name is Joshua Stanley, nice to meet you!", key: -1});
                    break;
                case 2:
                    copy.push({isOwner: false, msg: "I am a full stack software engineer with experience in each aspect of software development", key: -1});
                    break;
                case 3:
                    copy.push({isOwner: false, msg: "I am very well thank you, I hope you are as well :)", key: -1});
                    break;
                case 4:
                    copy.push({isOwner: false, msg: "You can contact me via email: neostanley@hotmail.co.uk", key: -1});
                    break;
                case 5:
                    QueueMsgs([
                        "For my GCSE's I have: A* In Maths, PE, ECDL. A's in Chemistry, Physics, Statistics, B's in Computer Science, Biology, English Language, C's in English Literature, Spanish, Food & Nutrition, Religious Studies",
                        "For my A Levels, I have: A in Computer Science, C in Mathematics, Distinction in Sports BTEC Level 3",
                        "I have also achieved a 2:1 in Digital & Technology Solutions Bcs"
                    ]);
                    return;
                case 6:
                    QueueMsgs([
                        "I have many technical skills such as knowledge in the following languages: Python, C#, Javascript/Typescript, Java, .NET",
                        "I also have experience in the following technical areas: SQL, APIs, REACT, Unit Testing, Unity, JSON, Blender, Git, Docker, LoRaWAN, CI, CD, OPC Servers"
                    ])
                    return;
            }
            console.log(copy)
            setMessages(copy);
            setLastMsg(copy[copy.length - 1]);
        }
    }, [lastMsg]);

    function QueueMsgs(msgs: string[]){
        setTyping(true);
        const copy = [...messages];
        copy.push({isOwner: false, msg: msgs[0], key: -1});
        setMessages(copy);
        setLastMsg(copy[copy.length-1]);
        msgs.forEach((m,idx) => {
            if (idx === 0) return;
            setTimeout(() => {
                const newMsg : MessageInfo = {isOwner: false, msg: m, key: -1};
                copy.push(newMsg);
                setMessages(copy);
                setLastMsg(copy[copy.length - 1]);
                if (idx === msgs.length - 1) setTyping(false);
            }, idx * 1000);
        })
    }

    function GetTime(time: string) : string{
        const split = time.split(":");
        const hourMins = [split[0], split[1]];
        const mornAft = split[2].split(" ")[1];
        return hourMins.join(":") + (mornAft ? mornAft : "")
    }

    return <div className="phone" onClick={(e) => e.stopPropagation()}>
        <div className="header" style={{textAlign: "center"}}>
            <div style={{marginRight: "auto"}}>
                <i className="fa-solid fa-clock fa-xs" style={{marginRight: "3px"}}></i>
                {GetTime(currentTime)}
            </div>
            <div style={{marginRight: "1%"}}>
                <i className="fa-solid fa-ellipsis"></i>
                <i className="fa-solid fa-wifi fa-sm"></i>
                <i className="fa-solid fa-battery-half"></i>
            </div>
        </div>
        <div className="history" ref={historyRef}>
        {messages.map((m, idx) => {
            return <div key={idx} className={m.isOwner ? "owner" : "recipient"}>
                <div>{m.msg}</div>
            </div>
        })}
        </div>
        {typing ? <DotsLoader /> : <div className="loader-placeholder" />}
        <div className="chatbox">
            <div className="dropup">
                <button className="dropbtn" disabled={typing} onClick={() => {setShowQuestions(true)}} 
                    onPointerLeave={() => {if (!isMobile) setShowQuestions(false)}}>Questions</button>
                <div className="dropup-content" style={{display: showQuestions ? "block" : "none"}} 
                    onPointerOver={() => {if (!isMobile) setShowQuestions(true)}} onPointerLeave={() => {if (!isMobile) setShowQuestions(false)}}>
                    <div onClick={() => AddQuestion("Who is this?", 1)}>Who is this?</div>
                    <div onClick={() => AddQuestion("What do you do?", 2)}>What do you do?</div>
                    <div onClick={() => AddQuestion("How are you?", 3)}>How are you?</div>
                    <div onClick={() => AddQuestion("How else can I contact you?", 4)}>How else can I contact you?</div>
                    <div onClick={() => AddQuestion("What qualifications do you have?", 5)}>What qualifications do you have?</div>
                    <div onClick={() => AddQuestion("What skills do you posess?", 6)}>What skills do you posess?</div>
                </div>
            </div>
        </div>
    </div>
}

export interface MessageInfo{
    isOwner: boolean;
    msg: string;
    key: number;
}