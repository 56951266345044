import { isMobile } from "react-device-detect";

export function SchoolDescription(){
    return <div>
        <p>I attended Archway Secondary School in Gloucesetershire during my GCSE years between 2012-2017.</p>
        <p>I then continued on at Archway for my A-Levels from 2017-2019. During this time I worked in the school's sports center as a "recreation assistant", 
            helping to manage the facilities,
            take bookings, setting up equipment and keeping up the general tidiness of the facility.
        </p>
        <p>Click <a href="./about" className="clickable-link">here</a> to find my qualifications</p>
    </div>
}

export function CapgeminiDescription(){
    const imgScale = "5%";

    return <div>
        <p>Straight after completing my A-Levels, I joined a Degree Level Apprenticeship programme in Digital & Technology Solutions with a company called Altran, 
            before they transitioned to be a part of Capgemini <img style={{height: imgScale, width: imgScale}} src={`${process.env.PUBLIC_URL}/capgem logo.png`} alt="Capgemini logo" /> in 2022</p>
        <p>I completed the apprenticeship January 2024 and have continued to work for Capgemini ever since.</p>
        <div style={{top: isMobile ? "65vh" : "", position: isMobile ? "absolute" : "static"}}>
            <p>My job title is <b>"Manufacturing & Process Engineer"</b> working in the Smart Factory division where we work closely with clients to transforming their 
            factories/offices to intelligent digital twin.</p>
            <p>Leveraging many technical areas such as <b>IoT</b> for data gathering, <b>OPC</b> servers & <b>SCADA</b> for data modelling, <b>AI</b> for data intelligence.</p>
        </div>
    </div>
}