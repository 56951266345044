import React, { useEffect, useRef, useState } from 'react';
import { AnimateProps } from '../utils/types';
import { Text, useGLTF } from '@react-three/drei';
import { Mesh, BufferGeometry, NormalBufferAttributes, Material, Object3DEventMap, Vector3 } from 'three';
import { useFrame } from '@react-three/fiber';
import { Select } from '@react-three/postprocessing';
import { useNavigate } from 'react-router-dom';

export default function TeePee(props: AnimateProps){
    const text = useRef<Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[], Object3DEventMap>>(null);
    const [hover, setHover] = useState(false);
    const obj = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/teepee.gltf`);
    const navigate = useNavigate();

    const pos = new Vector3(6,1,10);
    const lookAt = new Vector3(8.7,1,9.5);

    obj.scene.traverse(c => {
        c.castShadow = true;
        c.receiveShadow = true;
    })

    useFrame((state, delta) => {
        text.current?.lookAt(state.camera.position);
    });

    useEffect(() => {
        text.current?.geometry.computeBoundingBox();
        const boundingBox = text.current?.geometry.boundingBox;
        const center = new Vector3();
        boundingBox?.getCenter(center);
        text.current?.geometry.translate(-center.x, -center.y, -center.z);
    }, []);

    function OnAnimationFinished(){
        navigate("/career");
    }

    return <group dispose={null} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)}>
        <Text ref={text} font={`${process.env.PUBLIC_URL}/Agbalumo-Regular.woff`} position={[9, 5.5, 9.5]} scale={1.2}>
            Career
        </Text>
        <Select enabled={hover} onPointerDown={() => props.focus("teepee", pos, lookAt, OnAnimationFinished)}>
            <primitive object={obj.scene} />
        </Select>
    </group>
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/teepee.gltf`);