import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useRef } from "react";
import { AnimationMixer } from "three";
import { DreiGLTF } from "../../../utils/types";
import Screen from "./Screen";

export default function Environment(){
    const {nodes,animations, scene, materials} = useGLTF(`${process.env.PUBLIC_URL}/blender/dist/projects/IoT/IoT.gltf`) as DreiGLTF;
    const mixer = useRef(new AnimationMixer(scene));

    if (animations.length){
        animations.forEach(clip => {
            const action = mixer.current.clipAction(clip);
            action.play();
        })
    }

    scene.traverse(child => {
        child.castShadow = true;
        child.receiveShadow = true;
    })

    useFrame((_, delta) => {
        mixer.current.update(delta);
    });

    return <>
        <primitive object={scene} />
        <Screen nodes={nodes} materials={materials} scene={scene} />
    </>
}

useGLTF.preload(`${process.env.PUBLIC_URL}/blender/dist/projects/IoT/IoT.gltf`);