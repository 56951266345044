import { ContactShadows, Float, OrbitControls, Text } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Perf } from "r3f-perf";
import React, { Suspense } from "react";
import { Vector3 } from "three";
import Environment from "./Environment";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading";
import { isMobile } from "react-device-detect";

export default function OCR(){
    const { color, opacity, blur } = {color: "#FFFFFF", opacity: 0.43, blur: 3.4};
    const description = ["OCR", "This was a work project in which a client had image scans of excel documents",
        "but had lost the digital versions of these documents", 
        "and needed to repopulate excel documents with their content from the scans.",
        "I used Python Tesseract and OpenCV to detect boxes and perform Optical",
        "Character Recognition to repopulate and save these excel documents."
        ]
    const navigate = useNavigate();
    
    return <Suspense fallback={<Loading />}>
    <div id='home' onClick={() => navigate("/projects")}><i className="fa-solid fa-door-open fa-2xl"></i></div>
    <Canvas camera={{
        fov: isMobile ? 80 : 45,
        near: 0.1,
        far: 200,
        position: new Vector3(0,6,14)
        }}>
            <ContactShadows
                position={ [ 0, -5, 0 ] }
                scale={ 5 }
                resolution={ 512 }
                far={ 5 }
                color={ color }
                opacity={ opacity }
                blur={ blur }
                frames={ 1 }
            />
            <color args={ [ '#505454' ] } attach="background" />
            <OrbitControls makeDefault target={[2,5,0]} maxDistance={30} minDistance={8} enablePan={false} />
            {process.env.REACT_APP_PERF === "true" && <Perf position="top-right" />}
            <ambientLight intensity={5} />
            <Suspense fallback="Loading">
                <Float rotationIntensity={ 0.7 } >
                    <Environment />
                </Float>
                {description.map((d, idx) => {
                    let xPos = 2.3;
                    // if (idx === 1) xPos = -4;
                    // if (idx === 2) xPos = -2;
                    // if (idx === 3) xPos = -4;
                    // if (idx === 4) xPos = -3.5;
                    // if (idx === 5) xPos = -3.2;
                    return <Text key={idx} position={[xPos, 8.5 - (idx * 0.5), 0]} scale-z={0.4} fontSize={0.4}
                    font={`${process.env.PUBLIC_URL}/Agbalumo-Regular.woff`}>
                        {d}
                    </Text>})}
            </Suspense>
        </Canvas>
        </Suspense>
}