import { Box, OrbitControls, Text } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Perf } from "r3f-perf";
import React, { Suspense } from "react";
import { Vector3 } from "three";
import Environment from "./Environment";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading";
import { isMobile } from "react-device-detect";

export default function IOT(){
    const navigate = useNavigate();

    return <Suspense fallback={<Loading />}>
    <div id='home' onClick={() => navigate("/projects")}><i className="fa-solid fa-door-open fa-2xl"></i></div>
    <Canvas camera={{
        fov: isMobile ? 80 : 45,
        near: 0.1,
        far: 200,
        position: new Vector3(0,9,19)
        }}>
        <color args={ [ '#505454' ] } attach="background" />
        <OrbitControls makeDefault target={[11,3,0]} maxDistance={isMobile ? 50 : 30} minDistance={8} enablePan={false} />
        {process.env.REACT_APP_PERF === "true" && <Perf position="top-right" />}
        <ambientLight intensity={1} />
        <directionalLight position={[5,5,5]} intensity={1} />
        <Suspense fallback="Loading">
            <Environment />
            <Box rotation-x={-Math.PI / 2} scale={[20,10,0.2]} position={[8,-0.13,2]} receiveShadow>
                <meshBasicMaterial color={"#7B9696"} />
            </Box>
            <group>
                <Text position={[10,12,0]} font={`${process.env.PUBLIC_URL}/Agbalumo-Regular.woff`}>
                    In this IOT work project, we had to setup an end-to-end IOT system,
                </Text>
                <Text position={[10,11,0]} font={`${process.env.PUBLIC_URL}/Agbalumo-Regular.woff`}>
                    using multiple sensor devices such as Temperature, Humidity, Pressure etc.
                </Text>
                <Text position={[10,10,0]} font={`${process.env.PUBLIC_URL}/Agbalumo-Regular.woff`}>
                    The sensors would emit their values over a LoRaWAN frequency which would get
                </Text>
                <Text position={[10,9,0]} font={`${process.env.PUBLIC_URL}/Agbalumo-Regular.woff`}>
                    picked up from the Cisco Gateway antennas. The Cisco Gateway would then forward
                </Text>
                <Text position={[10,8,0]} font={`${process.env.PUBLIC_URL}/Agbalumo-Regular.woff`}>
                    the data to a running MQTT broker which we would then use Chirpstack to visualise
                </Text>
                <Text position={[10,7,0]} font={`${process.env.PUBLIC_URL}/Agbalumo-Regular.woff`}>
                    and stream the data.
                </Text>
            </group>
        </Suspense>
    </Canvas>
    </Suspense>
}